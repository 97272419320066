import React from "react"
import Layout from "../../Layout"

import Code from "../../components/Code"

const AuthenticationPage = () => {
  return (
    <Layout
      title={"Authentication"}
      description={"How to authenticate API requests"}
    >
      <div className="row">
        <div className="col-md-10 col-xl-8">
          <p>
            Worksimply uses API keys to authenticate the API requests. For every
            API request, include your API key in the header in the following
            format:
          </p>

          <Code lang={"bash"}>{codeString}</Code>

          <p>
            Make sure to replace <code>{`<api_key>`}</code> with your API key.
          </p>

          <p>
            Make sure that you use the API key only on your projects. You are
            responsible for all the actions made using your API key.
          </p>

          <p>
            To get your API key and get started with the Worksimply API please
            contact us.
          </p>
        </div>
      </div>
    </Layout>
  )
}

export default AuthenticationPage

const codeString = `// The bearer token is to be passed in the header
curl --location --request GET '<ENDPOINT>' \\
-H 'Authorization: Bearer <API_TOKEN>'`
